import React, { useEffect, useState } from 'react';
import './leaders-stats.css';
// import leaders from './data/leaders.json';

// const orderedLeaders = Object.values(leaders).sort((l1, l2) => l1.name.localeCompare(l2.name));

let leaders = []

const LeadersStats = () => {
    const [message, setMessage] = useState('Loading leaders...');
    const [leaderList, setLeaderList] = useState(leaders);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_ASSETS_URL}/assets/leaders.json`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((json) => {
                leaders = Object.values(json).sort((l1, l2) => l1.name.localeCompare(l2.name));
                setMessage(null);
                setLeaderList(leaders);
            })
            .catch((error) => {
                setMessage('Failed to load leaders');
                console.error("Error fetching or processing JSON:", error.message);
            });
    }, []);

    
    return (
        <div className="leaders-page">
            <h1>Leaders</h1>
            {message ? (
                <p>{message}</p>
            ) : (
                <div>
                    <ul className="leader-list">
                        {leaderList.map((leader) => {
                            return (
                                <li key={ leader.name }>
                                <article className="leader">
                                    <header className="leader-header">
                                        <h3 className="leader-title">{ leader.name }</h3>
                                    </header>
                                    <main className="leader-main">
                                        <figure className="leader-icon">
                                            <img src={ `${process.env.REACT_APP_ASSETS_URL}/${leader.icon}` } alt={ leader.name } />
                                        </figure>
                                        <section className="leader-powers">
                                        </section>
                                        {leader.powers.map((power) => {
                                            return (
                                                <section className="power">
                                                    <section className="power-header">
                                                        <img className="power-icon" src={ `${process.env.REACT_APP_ASSETS_URL}/${power.icon}` } alt={ power.name } />
                                                        <div className="power-name">{ power.name }</div>
                                                    </section>
                                                    <section className="power-description">
                                                        <p dangerouslySetInnerHTML={{ __html: power.description.replace(/\r?\n/g, '<br>') }}/>
                                                    </section>
                                                </section>
                                            )
                                        })}
                                    </main>
                                </article>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default LeadersStats;
